<template>
  <div>
    <b-alert :show="isNodata" class="w-50 w-100" variant="danger" fade>
      <div class="alert-body text-center">
        <feather-icon icon="InfoIcon" class="mr-50" />
        <span>ไม่มีข้อมูล</span>
      </div>
    </b-alert>

    <div class="row">
      <div v-for="(item, index) in items" :key="index" class="col-12 col-md-6 col-lg-4">
        <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <div class="card">
            <div class="item-img" v-if="item.urlImage.length" @click="modalHandle(item)">
              <ImageGrid :images="item.urlImage" />
            </div>
            <div v-else>
              <div class="item-img" style="max-width: 100%; height: 300px; object-fit: cover">
                <b-overlay show spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
              </div>
            </div>

            <div class="card-body">
              <div class="card-title h6">
                {{ item.topic }}
              </div>

              <p class="card-text">
                <ReadMore :text="item.detail" :truncatedLength="200" />
              </p>
              <div class="d-flex">
                <div v-for="(tags, index) in item.tag" :key="index" class="card-text">
                  <b-badge class="col-auto" variant="primary">
                    <feather-icon icon="HashIcon" class="mr-25" />
                    <span>{{ tags }}</span>
                  </b-badge>
                </div>
              </div>
            </div>

            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <a :href="item.weblink != '-' ? item.weblink : ''" target="_blank"><feather-icon icon="MousePointerIcon" class="mr-25" /> Web Link</a>
              </div>
              <div
                class="list-group-item text-primary"
                @click="
                  () => {
                    $store.dispatch('addStates', item);
                    $router.push({ name: 'mapsingle' });
                  }
                "
              >
                <a><feather-icon icon="MapPinIcon" class="mr-25" />GPS</a>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-between flex-wrap">
              <div class="flex-wrap">
                <small class="text-muted">
                  Create: <cite>{{ formatToThaiTime(item.showDate) }}</cite>
                </small>
              </div>
              <div class="flex-wrap">
                <small class="text-muted">
                  Update: <cite>{{ formatTofromNow(item.registerDate) }}</cite>
                </small>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <ModalPaper :isVisible="isModalVisible" :title="modalTitle" :content="modalContent" @close="hideModal" />
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  import ImageGrid from '../Components/ImageGrid.vue';
  import ReadMore from '../Components/ReadMore.vue';
  import Gallery from '../Components/Gallery.vue';
  import ModalPaper from '../Components/ModalPaper.vue';

  export default {
    components: {
      ModalPaper,
      Gallery,
      ReadMore,
      ImageGrid,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        categoryName: 'business',
        currentPage: 1, // จำนวนรายการที่แสดงในแต่ละหน้า
        perPage: 8, // จำนวนรายการที่แสดงในแต่ละหน้า
        totalPage: 0, // จำนวนรายการ
        playLoad: true,
        isNodata: false,

        items: [],
        searchItems: '',
        //modal
        isModalVisible: false,
        modalContent: [],
        modalTitle: '',

        API: '',
        access_token: '',
        swiperOptions: {
          lazy: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      };
    },

    created() {
      this.getDataTable();
    },

    methods: {
      modalHandle(value) {
        this.$nextTick(() => {
          this.modalContent = [];
          this.modalTitle = value.topic;
          this.modalContent = [value];
          this.$bvModal.show('modal-1');
        });
      },
      hideModal() {
        this.isModalVisible = false;
      },
      formatTofromNow(date) {
        // เวลาที่อัพเดท
        return dayjs().locale('th').to(dayjs(date));
      },
      formatToThaiTime(date) {
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').locale('th').format('DD-MMM-BBBB');
      },

      async searchDataTable() {
        this.playLoad = true;
        if (this.searchItems) {
          const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
          this.$store
            .dispatch('getData', `announce/${userId}?category=${this.categoryName}&topic=${this.searchItems}&_page=1`)
            .then(async (res) => {
              console.log(res.message);

              await res.message.result.forEach((el) => {
                const image = [];

                if (el.announceImage.length) {
                  el.announceImage.map(async (el) => {
                    image.push(await this.$store.dispatch('getImageURL', el).then((res) => res));
                  });
                } else {
                  image.push('https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain');
                }

                el.urlImage = image;
              });

              this.items = res.message.result;
              //this.totalPage = res.message.total;
              this.playLoad = false;
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          console.log('New table');
          this.getDataTable();
        }
      },
      async getDataTable() {
        this.playLoad = true;
        this.$store
          .dispatch('get_announce_filter', this.categoryName)
          .then(async (res) => {
            await res.forEach((el) => {
              const image = [];

              if (el.announceImage.length) {
                el.announceImage.map(async (el) => {
                  image.push(await this.$store.dispatch('getImageURL', el).then((res) => res));
                });
              } else {
                image.push('https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain');
              }
              el.urlImage = image;
            });
            const data = [];
            res.forEach((el) => {
              // console.log(dayjs().isAfter(dayjs(el.showDate)));
              if (dayjs().isAfter(dayjs(el.showDate))) {
                data.push(el);
              }
            });
            this.$store.commit('get_announce_filter', data);

            // this.items.slice(start, end);
            // const start = (this.currentPage - 1) * this.perPage;
            // const end = start + this.perPage;
            //this.items = data.slice(start, end);
            this.items = data;
            this.totalPage = data.length;
            this.isNodata = data.length == 0 ? true : false;
            this.playLoad = false;
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/swiper.scss';
  .item-description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
