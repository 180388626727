<template>
  <div>
    <b-modal id="modal-1" :title="title" ok-only centered no-close-on-backdrop ok-title="ปิด">
      <div v-for="(item, index) in content" :key="index">
        <Gallery :images="item.urlImage" />
        <div>
          <div class="card-title h6 mt-1">
            {{ item.topic }}
          </div>

          <p class="card-text">
            {{ item.detail }}
          </p>
          <div class="d-flex">
            <div v-for="(tags, index) in item.tag" :key="index" class="card-text">
              <b-badge class="col-auto" variant="primary">
                <feather-icon icon="HashIcon" class="mr-25" />
                <span>{{ tags }}</span>
              </b-badge>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item">
              <a :href="item.weblink != '-' ? item.weblink : ''" target="_blank"><feather-icon icon="MousePointerIcon" class="mr-25" /> Web Link</a>
            </div>
            <div class="list-group-item text-primary" @click="() => {
      $store.dispatch('addStates', item);
      $router.push({ name: 'mapsingle' });
    }
      ">
              <a><feather-icon icon="MapPinIcon" class="mr-25" />GPS</a>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-wrap">
            <div class="flex-wrap">
              <small class="text-muted">
                Create: <cite>{{ formatToThaiTime(item.showDate) }}</cite>
              </small>
            </div>
            <div class="flex-wrap">
              <small class="text-muted">
                Update: <cite>{{ formatTofromNow(item.registerDate) }}</cite>
              </small>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Gallery from '../Components/Gallery.vue';

import dayjs from 'dayjs';
import 'dayjs/locale/th';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const buddhistEra = require('dayjs/plugin/buddhistEra');
dayjs.extend(buddhistEra);
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
export default {
  components: {
    Gallery,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'No title provided.',
    },
    content: {
      type: Array,
      default: 'No content provided.',
    },
  },
  async created() {
    this.$nextTick(async () => {
      await this.content
    });

  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    formatTofromNow(date) {
      // เวลาที่อัพเดท
      return dayjs().locale('th').to(dayjs(date));
    },
    formatToThaiTime(date) {
      // เปลี่ยนวันที่ไทย
      return dayjs.tz(date, 'Asia/Bangkok').locale('th').format('DD-MMM-BBBB');
    },
  },
};
</script>

<style scoped>
b-modal {
  padding: 0.8rem 1.4rem !important;
}
</style>
